<template>
  <div data-app>
    <v-app>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="searchJobbers"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headerJobbers"
            :items="jobbers"
            :search="searchJobbers"
            class="table-striped elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Listado</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <ExportarTabla
                  filename="Tareas por pagar - Jobbers"
                  :data="jobbers"
                  :header="headerJobbers"
                />
              </v-toolbar>
            </template>
            <template v-slot:[`header.selected`]>
              <v-switch
                v-model="selectAllJobbers"
                @change="changeSelectAllJobbers()"
                :value="true"
              ></v-switch>
            </template>
            <template v-slot:[`item.selected`]="{ item }">
              <v-switch
                v-model="item.selected"
                v-on:change="changeSelectedState"
              ></v-switch>
            </template>
            <template v-slot:[`item.cantidadGestiones`]="{ item }">
              {{
                headerJobbers
                  .find((aHeader) => aHeader.value === "cantidadGestiones")
                  .render(item)
              }}
            </template>
            <template v-slot:[`item.costoTarea`]="{ item }">
              {{
                headerJobbers
                  .find((aHeader) => aHeader.value === "costoTarea")
                  .render(item)
              }}
            </template>
            <template v-slot:[`item.reembolsoCosto`]="{ item }">
              {{
                headerJobbers
                  .find((aHeader) => aHeader.value === "reembolsoCosto")
                  .render(item)
              }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{
                headerJobbers
                  .find((aHeader) => aHeader.value === "total")
                  .render(item)
              }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showTask(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-app>
    <TaskListDialog ref="TaskListDialog" />
  </div>
</template>
<script>
import TaskListDialog from "../task/TaskListDialog.vue";
import ExportarTabla from "../globals/ExportarTabla.vue";
export default {
  name: "LiquidationsJobbers",
  props: {
    jobbers: Array,
  },
  components: {
    TaskListDialog,
    ExportarTabla,
  },
  data() {
    const oVue = this;
    return {
      internalJobbers: oVue.jobbers,
      headerJobbers: [
        {
          text: "",
          value: "selected",
          sortable: false,
        },
        {
          text: "Jobber",
          value: "nombreJobber",
        },
        {
          text: "Gestiones",
          value: "cantidadGestiones",
          render: (item) =>
            item.tareas
              .reduce(
                (iSumatoriaGestiones, aActualValue) =>
                  (iSumatoriaGestiones += aActualValue.cantidadGestiones),
                0
              )
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join(","),
        },
        {
          text: "Costo",
          value: "costoTarea",
          render: (item) =>
            item.tareas
              .reduce(
                (iSumatoriaCosto, aActualValue) =>
                  (iSumatoriaCosto += aActualValue.costoTarea),
                0
              )
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join(","),
        },
        {
          text: "Reembolso",
          value: "reembolsoCosto",
          render: (item) =>
            item.tareas
              .reduce(
                (iSumatoriaReembolso, aActualValue) =>
                  (iSumatoriaReembolso += aActualValue.reembolsoCosto),
                0
              )
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join(","),
        },
        {
          text: "Total",
          value: "total",
          render: (item) =>
            item.tareas
              .reduce(
                (iSumatoriaTotal, aActualValue) =>
                  (iSumatoriaTotal +=
                    aActualValue.costoTarea * aActualValue.cantidadGestiones +
                    aActualValue.reembolsoCosto),
                0
              )
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join(","),
        },
        {
          text: "",
          value: "actions",
        },
      ],
      searchJobbers: "",
      selectAllJobbers: false,
    };
  },
  created() {},
  methods: {
    changeSelectAllJobbers() {
      const oVue = this;
      const bSelectAll = oVue.selectAllJobbers === null ? false : true;
      const aJobbers = oVue.jobbers.map((aJobber) => {
        aJobber.selected = bSelectAll;
        return {
          ...aJobber,
        };
      });
      oVue.internalJobbers = aJobbers;
      oVue.returnSelectedJobbers();
    },
    changeSelectedState(aJobber) {
      const oVue = this;
      const iJobberID = aJobber.jobber;
      const aJobbers = oVue.jobbers.map((aInsideJobber) => {
        if (aInsideJobber.jobber === iJobberID) {
          aInsideJobber.selected = !aInsideJobber.selected;
        }
        return {
          ...aInsideJobber,
        };
      });
      oVue.internalJobbers = aJobbers;
      oVue.returnSelectedJobbers();
    },
    returnSelectedJobbers() {
      const oVue = this;

      const aSelectedJobbers = oVue.internalJobbers.filter((aJobber) => {
        return aJobber.selected;
      });
      oVue.$emit("getSelectedJobbers", aSelectedJobbers);
    },
    showTask(aJobber) {
      const oVue = this;
      const aTasks = aJobber.tareas;
      const sTasks = aTasks.map((aTask) => aTask.idTarea).join(",");
      oVue.$refs.TaskListDialog.getTasks({ tasks: sTasks });
    },
  },
  watch: {
    internalJobbers() {
      const oVue = this;
      oVue.returnSelectedJobbers();
    },
  },
};
</script>
