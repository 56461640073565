<template>
  <div>
    <v-data-table
      :headers="headersTasks"
      :items="tasks"
      class="table-striped elevation-1"
      :hide-default-footer="!!pagination.page"
      :items-per-page="pageSize"
      :sort-options="sortOptions"
      @update:options="sortColumnsHandler"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listado de Tareas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display: inline-flex">
          <TaskDetail :taskID="item.tareaID"></TaskDetail>
          <TaskEdit
            v-if="
              Number(currentUserPersonalInfo.perfil_id) === 1 && tipo === 'list'
            "
            :taskID="item.tareaID"
            :estados="estados"
            v-bind:getTasks="getTasks"
          ></TaskEdit>
        </div>
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <span :class="item.estadoID == 1 ? 'text-danger' : 'text-success'">
          {{ item.estado }}
        </span>
      </template>
    </v-data-table>
    <v-col v-if="pagination.page" cols="12" sm="12">
      <v-row>
        <v-col cols="4" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Filas por página"
            @change="paginationSizeHandler"
          ></v-select>
        </v-col>
        <v-col cols="8" sm="10">
          <v-pagination
            v-model="page"
            :length="pagination?.totalPages"
            @input="paginationPageHandler"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TaskDetail from "./TaskDetail.vue";
import TaskEdit from "./TaskEdit.vue";
export default {
  name: "TaskList",
  components: {
    TaskDetail,
    TaskEdit,
  },
  props: {
    tasks: Array,
    tipo: String,
    estados: Array,
    getTasks: Function,
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      page: 1,
      totalPages: 2,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      sortOptions: {
        sortBy: ["tareaID"],
        sortDesc: [false],
      },
      headersTasks: [
        {
          text: "ID Tarea",
          align: "center",
          value: "tareaID",
          width: "9em",
        },
        {
          text: "Mandante",
          value: "mandanteNombre",
          width: "10em",
        },
        {
          text: "ID Campaña",
          value: "campanaID",
          align: "center",
          width: "11em",
        },
        {
          text: "Campaña",
          value: "campanaNombre",
          width: "15em",
        },
        {
          text: "Jobber",
          value: "jobberNombre",
          width: "10em",
        },
        {
          text: "Fecha Asigna",
          value: "fechaAsigna",
          width: "12em",
        },
        {
          text: "Fecha Gestión",
          value: "fechaGestion",
          width: "12em",
        },
        {
          text: "Estado",
          value: "estado",
          width: "5em",
        },
        {
          text: "Atributo 1",
          value: "atributo1",
          sortable: false,
          width: "9em",
        },
        {
          text: "Atributo 2",
          value: "atributo2",
          sortable: false,
          width: "9em",
        },
        {
          text: "Atributo 3",
          value: "atributo3",
          sortable: false,
          width: "9em",
        },
        {
          text: "Atributo 4",
          value: "atributo4",
          sortable: false,
          width: "9em",
        },
        { text: "", value: "actions", sortable: false },
      ],
      search: "",
      awaitingSearch: false,
    };
  },
  watch: {
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.dataSourceHandler();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  methods: {
    paginationSizeHandler(value) {
      this.$emit("paginationSizeEvent", value);
    },
    paginationPageHandler(value) {
      this.$emit("paginationPageEvent", value);
    },
    sortColumnsHandler(options) {
      if (!this.pagination.page) return;

      this.sortOptions.sortBy = options.sortBy?.[0] || "";
      this.sortOptions.sortDesc = options.sortDesc?.[0] || false;

      this.dataSourceHandler();
    },
    dataSourceHandler() {
      const data = {
        sortBy: this.sortOptions.sortBy,
        sortDesc: this.sortOptions.sortDesc,
        search: this.search,
        pageSize: this.pageSize,
      };
      this.$emit("dataSourceEvent", data);
    },
  },
};
</script>
