<template>
  <v-row>
    <v-col cols="6"> {{ title }}: </v-col>
    <v-col cols="6">
      {{
        value
          .toString()
          .split(/(?=(?:\d{3})+(?:\.|$))/g)
          .join(",")
      }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "LiquidationsPayableAmountsRow",
  props: {
    title: String,
    value: Number,
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {},
};
</script>
