<template>
  <div>
    <v-dialog
      v-model="dialogShowTasks"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="dialogShowTasks = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tareas</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120 pt-1 pb-4">
            <v-col cols="12">
              <div class="card card-custom card-stretch gutter-b">
                <!-- <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Tareas</h3>
                  </div>
                </div> -->
                <div class="card-body p-0">
                  <TaskList :tasks="tasks" :tipo="'dialog'"></TaskList>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import { GET_TASK_FILTERED } from "@/core/services/store/task.module";
import TaskList from "./TaskList.vue";
export default {
  name: "TaskListDialog",
  components: {
    TaskList,
  },
  data() {
    return {
      dialogShowTasks: false,
      tasks: [],
    };
  },
  methods: {
    getTasks(params) {
      KTUtil.setLoading();
      const oVue = this;
      oVue.$store.dispatch(GET_TASK_FILTERED, params).then((tasks) => {
        oVue.setTasks(tasks);
      });
    },
    setTasks(tasks) {
      const oVue = this;
      oVue.tasks = tasks;
      oVue.dialogShowTasks = true;
      KTUtil.removeLoading();
    },
  },
};
</script>
