<template>
  <v-btn
    color="primary float-right"
    dark
    class="mb-2"
    v-on:click="downloadExcel()"
  >
    Exportar
  </v-btn>
</template>
<script>
import { downloadExcelFile } from "../../../helpers/xlsx";
export default {
  name: "ExportarTabla",
  props: {
    filename: String,
    data: Array,
    header: Array,
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    downloadExcel() {
      downloadExcelFile(this.filename, this.header, this.data);
    },
  },
  watch: {},
};
</script>
