<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div class="col-md-12">
            <div
              class="wizard wizard-3"
              id="kt_wizard"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav -->
              <div class="wizard-nav border-bottom mb-1 mb-lg-5">
                <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-label">
                      <h3 class="wizard-title">
                        <span>1</span> Selección de Fechas
                      </h3>
                      <div class="wizard-bar"></div>
                    </div>
                  </div>
                  <div class="wizard-step" data-wizard-type="step">
                    <div class="wizard-label">
                      <h3 class="wizard-title">
                        <span>2</span> Selección de tareas
                      </h3>
                      <div class="wizard-bar"></div>
                    </div>
                  </div>
                  <div class="wizard-step" data-wizard-type="step">
                    <div class="wizard-label">
                      <h3 class="wizard-title">
                        <span>3</span> Seleccion de Jobbers
                      </h3>
                      <div class="wizard-bar"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Nav -->
              <!--begin: Wizard Body-->
              <div
                class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10"
              >
                <div class="col-md-12 col-xxl-7">
                  <!--begin: Wizard Form-->
                  <form class="form" id="kt_form">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="recurrenciaPago"
                            :items="recurrenciasPagos"
                            item-value="id"
                            item-text="recurrencia"
                            label="Recurrencia de Pago"
                            dense
                            @change="() => {}"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-subheader>Fecha Inicio / Termino</v-subheader>
                        </v-col>
                        <v-col cols="6">
                          <v-date-picker
                            v-model="dateRange"
                            range
                            dark
                            full-width
                            selected-items-text=""
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="dateRangeText"
                            label="Date range"
                            prepend-icon="mdi-calendar"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Step 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <v-row>
                        <v-col cols="12">
                          <PayableAmounts
                            :allTasks="tasks"
                            :selectedTasks="aSelectedTasks"
                          />
                        </v-col>
                        <v-col cols="12">
                          <Tasks
                            :tasks="tasks"
                            :tasksWithProblems="tasksWithProblems"
                            @getSelectedTasks="getSelectedTasks"
                          ></Tasks>
                        </v-col>
                      </v-row>
                    </div>
                    <!--end: Wizard Step 2-->

                    <!--begin: Wizard Step 3-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <v-row>
                        <v-col cols="12">
                          <PayableAmounts
                            :allTasks="aSelectedTasks"
                            :selectedTasks="aSelectedJobbersTasks"
                          />
                        </v-col>
                        <v-col cols="12">
                          <Jobbers
                            :jobbers="jobbers"
                            @getSelectedJobbers="getSelectedJobbers"
                          ></Jobbers>
                        </v-col>
                      </v-row>
                    </div>
                    <!--end: Wizard Step 3-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div class="mr-2">
                        <button
                          class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-prev"
                        >
                          Anterior
                        </button>
                      </div>
                      <div>
                        <button
                          v-on:click="sendForm"
                          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-submit"
                        >
                          Generar
                        </button>
                        <button
                          class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-next"
                        >
                          Siguiente
                        </button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import KTWizard from "@/assets/js/components/wizard";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_RECURRENCIAS_PAGOS } from "@/core/services/store/jobber.module";
import { GET_MANAGED_TASKS_FOR_LIQUIDATION } from "@/core/services/store/task.module";
import { GENERATE_JOBBERS_LIQUIDATIONS } from "@/core/services/store/liquidation.module";
import { mapGetters } from "vuex";
import Tasks from "../../components/liquidations/Tasks.vue";
import Jobbers from "../../components/liquidations/Jobbers.vue";
import PayableAmounts from "../../components/liquidations/PayableAmounts.vue";
export default {
  name: "create_liquidation",
  components: {
    Tasks,
    Jobbers,
    PayableAmounts,
  },
  props: {},
  data() {
    return {
      wizard: null,
      dateRange: [],
      tasks: [],
      jobbers: [],
      tasksWithProblems: [],
      aSelectedTasks: [],
      aSelectedJobbers: [],
      aSelectedJobbersTasks: [],
      recurrenciaPago: null,
      recurrenciasPagos: [],
    };
  },
  created() {
    const oVue = this;
    oVue.initializeWizard();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Liquidaciones" },
      { title: "Generacion de liquidación", route: "liquidations/new" },
    ]);
  },
  methods: {
    getTasks(sDateFrom, sDateTo) {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_MANAGED_TASKS_FOR_LIQUIDATION, {
          from: sDateFrom,
          to: sDateTo,
          recurrencia: oVue.recurrenciaPago,
        })
        .then(({ data: aTasks }) => {
          aTasks = aTasks.map((aTask) => {
            return {
              selected: false,
              ...aTask,
            };
          });
          oVue.tasks = aTasks.filter((aTask) => {
            return aTask.tareaConProblema === 0;
          });
          oVue.tasksWithProblems = aTasks.filter((aTask) => {
            return aTask.tareaConProblema === 1;
          });
          KTUtil.removeLoading();
        });
    },
    initializeWizard() {
      const oVue = this;

      oVue.getRecurrenciasPago();

      oVue.$nextTick(() => {
        // Initialize form wizard
        oVue.wizard = new KTWizard("kt_wizard", {
          startStep: 1, // initial active step number
          clickableSteps: true, // allow step clicking
        });

        let oWizard = oVue.wizard;

        oWizard.on("beforeNext", function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        });

        // Change event
        oWizard.on("change", async function (wizardObj) {
          let bCanNext = false;

          const iCurrentStep = Number(oWizard.currentStep);
          const iNextStep = Number(oWizard.newStep);

          //eslint-disable-next-line no-empty
          if (iCurrentStep + 1 < iNextStep) {
          } else {
            if (iNextStep < iCurrentStep) {
              bCanNext = true;
            }
          }

          switch (iCurrentStep) {
            case 1: {
              if (oVue.dateRange.length > 0) {
                bCanNext = true;
                const [sDateFrom, sDateTo] = oVue.dateRange;
                oVue.getTasks(sDateFrom, sDateTo);
              } else {
                Swal.fire({
                  title: "¡ERROR!",
                  text: "Debe seleccionar un rango de fecha.",
                  icon: "warning",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
              break;
            }
            case 2: {
              if (oVue.aSelectedTasks.length > 0) {
                bCanNext = true;
              } else {
                if (iCurrentStep < iNextStep) {
                  Swal.fire({
                    title: "¡ERROR!",
                    text: "Debe seleccionar al menos una tarea.",
                    icon: "warning",
                    confirmButtonClass: "btn btn-secondary",
                  });
                }
              }
              break;
            }
          }

          if (bCanNext === false) {
            wizardObj.stop();
          }
        });
      });
    },
    sendForm: async function (e) {
      e.preventDefault();

      const oVue = this;

      if (oVue.aSelectedJobbers.length > 0) {
        oVue.generateLiquidations();
      } else {
        Swal.fire({
          title: "¡ERROR!",
          text: "Debe seleccionar almenos un Jobber.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    getSelectedTasks(aTasks) {
      const oVue = this;
      oVue.aSelectedTasks = aTasks;
      oVue.jobbers = Object.values(
        oVue.aSelectedTasks.reduce(
          (aLastValue, aActualValue) => (
            (aLastValue[aActualValue.jobber] = aLastValue[aActualValue.jobber]
              ? aLastValue[aActualValue.jobber]
              : aActualValue),
            aLastValue
          ),
          {}
        )
      );
      oVue.jobbers = oVue.jobbers.map((aJobber) => {
        return {
          selected: false,
          jobber: aJobber.jobber,
          nombreJobber: aJobber.nombreJobber,
          tareas: oVue.tasks.filter((aTask) => {
            return aTask.jobber === aJobber.jobber && aTask.selected === true;
          }),
        };
      });
    },
    getSelectedJobbers(aJobbers) {
      const oVue = this;
      oVue.aSelectedJobbers = aJobbers;
      oVue.aSelectedJobbersTasks = [];
      oVue.aSelectedJobbers.map((aJobber) => {
        oVue.aSelectedJobbersTasks.push(...aJobber.tareas);
      });
    },
    generateLiquidations() {
      KTUtil.setLoading();
      let oVue = this;

      const aJobbers = oVue.aSelectedJobbers.map((aJobber) => {
        let aJobberReturn = aJobber;
        aJobberReturn.tareas = aJobberReturn.tareas.map((aTask) => {
          return {
            idTarea: aTask.idTarea,
          };
        });
        return aJobberReturn;
      });

      oVue.$store
        .dispatch(GENERATE_JOBBERS_LIQUIDATIONS, { jobbers: aJobbers })
        .then(({ result: bResult, message: sMessage }) => {
          if (bResult) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: "Liquidaciones creadas correctamente",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            }).then(() => {
              oVue.$router.push({ name: "liquidation_list" });
            });
          } else {
            Swal.fire({
              title: "¡ERROR!",
              text: sMessage,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          KTUtil.removeLoading();
        });
    },
    getRecurrenciasPago() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_RECURRENCIAS_PAGOS).then((response) => {
        oVue.recurrenciasPagos = response;
        oVue.recurrenciasPagos.unshift({ id: null, recurrencia: "Todas" });
        KTUtil.removeLoading();
      });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    dateRangeText() {
      let oVue = this;
      const aDates = oVue.dateRange;

      let sFechaIni = "";
      let sFechaFin = "";
      if (typeof aDates[0] !== "undefined") {
        sFechaIni = moment(aDates[0], "YYYY/MM/DD").format("DD-MM-YYYY");
      }
      if (typeof aDates[1] !== "undefined") {
        sFechaFin = moment(aDates[1], "YYYY/MM/DD").format("DD-MM-YYYY");
      }

      return sFechaIni + " ~ " + sFechaFin;
    },
  },
  watch: {
    aSelectedJobbers() {},
  },
};
</script>
