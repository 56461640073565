<template>
  <div>
    <v-icon small class="mr-2" @click="editTask()"> mdi-pencil </v-icon>
    <b-modal
      v-if="showModal"
      ref="modalTask"
      :title="`Tarea: ${taskData.tareaID}`"
      size="xl"
      hide-footer
    >
      <form class="form">
        <div class="pb-5">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="taskData.estadoTareaID"
                :items="estados"
                item-key="estadoID"
                item-value="estadoID"
                item-text="estadoDescripcion"
                label="Estado"
                dense
                autocomplete="new-password"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="taskData.comuna" label="Comuna" dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="taskData.latitud" label="Latitud" dense>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="taskData.longitud" label="Longitud" dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="taskData.fechaHoraAsigna"
                label="Fecha de Asignación"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="taskData.fechaHoraGestion"
                label="Fecha de Gestión"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="taskData.latitud_jobber"
                label="Latitud Jobber"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="taskData.longitud_jobber"
                label="Longitud Jobber"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="taskData.distancia_tarea_jobber"
                label="Distancia Tarea Jobber"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="taskData.idUsuario"
                :items="jobbers"
                item-key="jobberID"
                item-text="jobberNombre"
                item-value="jobberID"
                label="Jobber"
                dense
                autocomplete="new-password"
                readonly
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="taskData.costo"
                label="Costo"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="taskData.precio"
                label="Precio"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="taskData.reembolso_costo"
                label="Reembolso"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-for="field in fields.filter((f) => f.campoTipo !== 10)"
            :key="field.campoID"
          >
            <v-col cols="12">
              <v-text-field
                v-if="field.campoTipo !== 3"
                v-model="field.campoValor"
                :item-key="field.campoID"
                :item-value="field.campoValor"
                :item-text="field.campoTitulo"
                :label="field.campoTitulo"
                dense
              ></v-text-field>
              <v-autocomplete
                v-if="field.campoTipo === 3"
                v-model="field.campoValor"
                :items="options.filter((o) => o.idCampo === field.campoID)"
                item-key="idOpcion"
                item-value="opcion"
                item-text="opcion"
                :label="field.campoTitulo"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-for="image in images" :key="image.campoID">
            <v-col cols="12">
              <label class="font-size-h6 font-weight-bolder text-dark mb-1">
                {{ image.campoTitulo }}
              </label>
              <TaskImage
                :iCampaignID="taskData.campanaID"
                :sFilename="image.campoValor"
              >
              </TaskImage>
              <v-checkbox
                v-if="image.campoValor"
                v-model="image.campoEliminar"
                label="Eliminar Imagen"
              ></v-checkbox>
              <vue-dropzone
                :id="String(image.campoID)"
                :options="dropzoneOptions"
              ></vue-dropzone>
            </v-col>
          </v-row>

          <div class="d-flex justify-content-between pt-10 float-right">
            <v-btn
              @click="saveTask()"
              color="primary"
              dark
              class="font-weight-bold text-uppercase px-9 py-4"
            >
              Guardar
            </v-btn>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import TaskImage from "./TaskImage.vue";
import {
  GET_ALL_TASK_DATA,
  SAVE_TASK_DATA,
} from "@/core/services/store/task.module";
import { GET_FIELDS_OPTIONS_CAMPAIGN_BY_JOBBER } from "@/core/services/store/campaign.module";
export default {
  name: "TaskEdit",
  props: {
    taskID: Number,
    getTasks: Function,
    estados: Array,
    // jobbers: Array,
  },
  components: {
    vueDropzone: vue2Dropzone,
    TaskImage,
  },
  data() {
    return {
      showModal: true,
      taskData: {
        tareaID: null,
        campanaID: null,
        estadoTareaID: null,
        comuna: null,
        latitud: null,
        longitud: null,
        fechaHoraAsigna: null,
        idUsuario: null,
        fechaHoraGestion: null,
        latitud_jobber: null,
        longitud_jobber: null,
        distancia_tarea_jobber: null,
        costo: null,
        precio: null,
        reembolso_costo: null,
        attributes: [],
      },
      fields: [],
      images: [],
      jobbers: [],
      options: [],
      dropzoneOptions: {
        url: "/",
        paramName: "file",
        maxFiles: 1,
        maxFilesize: 30,
        addRemoveLinks: true,
        acceptedFiles: ".jpeg,.jpg,.png",
        autoProcessQueue: false,
        accept: function (file, done) {
          this.options.resizeWidth = 1024;
          this.options.resizeMimeType = "image/jpeg";
          this.options.resizeQuality = 0.75;
          done();
        },
        maxfilesexceeded: function (files) {
          this.removeAllFiles();
          this.addFile(files);
        },
      },
    };
  },
  methods: {
    async editTask() {
      const oVue = this;
      oVue.fields = [];

      const aAllTaskDataResponse = await oVue.getAllTaskData(oVue.taskID);
      if (aAllTaskDataResponse.result) {
        const aTaskData = aAllTaskDataResponse.data.general;
        oVue.taskData = aTaskData;

        const aAttributes = (oVue.taskData.attributes =
          typeof aTaskData.atributos !== "object"
            ? JSON.parse(aTaskData.atributos)
            : aTaskData.atributos);
        const aFields = aAllTaskDataResponse.data.fields;

        let aFinalFields = [];
        let aImages = [];

        for (let i = 0; i <= aFields.length - 1; i++) {
          const aField = aFields[i];
          const aAttribute = aAttributes[`campo_${aField.idCampo}`];

          let aNewField = {
            campoID: aField.idCampo,
            campoTitulo: aField.titulo,
            campoValor: aAttribute?.value,
            campoEvidencia: aAttribute?.evidencia_objetiva,
            campoTipo: aField.tipoID,
            campoEliminar: false,
          };
          aFinalFields.push(aNewField);
          aField.tipoID === 10 && aImages.push(aNewField);
        }
        oVue.fields = aFinalFields;
        oVue.images = aImages;
        const jobbers = [
          {
            jobberID: aTaskData.idUsuario,
            jobberNombre: aTaskData.jobberNombre,
          },
        ];
        oVue.jobbers = jobbers;

        oVue.dropzoneOptions.init = function () {
          const iFieldID = Number(this.element.id);
          this.on("addedfile", function (file) {
            const reader = new FileReader();
            reader.onload = function (event) {
              const base64String = event.target.result;
              oVue.setFile(iFieldID, base64String);
            };
            reader.readAsDataURL(file);
          });
        };
      }
      const aOptions = await oVue.getFieldsOptionsCampaignByJobber(
        oVue.taskData.campanaID
      );
      oVue.options = aOptions;
      oVue.$refs["modalTask"].show();
    },
    async getAllTaskData(iTaskID) {
      let oVue = this;
      oVue.setLoading();
      const aAllTaskDataResponse = await oVue.$store.dispatch(
        GET_ALL_TASK_DATA,
        { task: iTaskID }
      );
      oVue.removeLoading();
      return aAllTaskDataResponse;
    },
    saveTask() {
      let oVue = this;
      const taskData = oVue.taskData;
      oVue.setLoading();

      let aAttributes = {};

      oVue.fields.forEach((aField) => {
        aAttributes[`campo_${aField.campoID}`] = {
          value: !aField.campoEliminar ? aField.campoValor : "",
          evidencia_objectiva: aField.campoEvidencia,
        };
      });

      let oData = {
        id: taskData.tareaID,
        id_estado: taskData.estadoTareaID,
        comuna: taskData.comuna,
        latitud: taskData.latitud,
        longitud: taskData.longitud,
        fecha_asignacion: taskData.fechaHoraAsigna,
        id_usuario: taskData.idUsuario,
        fecha_gestion: taskData.fechaHoraGestion,
        latitud_jobber: taskData.latitud_jobber,
        longitud_jobber: taskData.longitud_jobber,
        distancia_tarea_jobber: taskData.distancia_tarea_jobber,
        costo: taskData.costo,
        precio: taskData.precio,
        reembolso_costo: taskData.reembolso_costo,
        atributos: aAttributes,
      };

      oVue.saveTaskData(oData).then((asaveTaskResponse) => {
        oVue.removeLoading();
        if (asaveTaskResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: `Los datos de la tarea fueron guardados exitosamente`,
            icon: "success",
          });
          oVue.getTasks();
        } else {
          Swal.fire({
            title: `Error al guardar la tarea`,
            text: asaveTaskResponse.message,
            icon: "error",
          });
        }
      });
    },
    async saveTaskData(aData) {
      let oVue = this;
      const asaveTaskResponse = await oVue.$store.dispatch(
        SAVE_TASK_DATA,
        aData
      );
      return asaveTaskResponse;
    },
    async setFile(iFieldID, sBase64) {
      let oVue = this;
      const aField = oVue.fields.find((f) => f.campoID === iFieldID);
      if (aField) aField.campoValor = sBase64;
    },
    async getFieldsOptionsCampaignByJobber(iCampaignID) {
      let oVue = this;
      oVue.setLoading();
      const aOptionsCampaignResponse = await oVue.$store.dispatch(
        GET_FIELDS_OPTIONS_CAMPAIGN_BY_JOBBER,
        {
          campaign: iCampaignID,
          jobber: oVue.currentUserPersonalInfo.usuarioID,
        }
      );
      oVue.removeLoading();
      return aOptionsCampaignResponse;
    },
    setLoading() {
      this.showModal = false;
      KTUtil.setLoading();
    },
    removeLoading() {
      this.showModal = true;
      KTUtil.removeLoading();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
