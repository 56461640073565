<template>
  <div data-app>
    <v-app>
      <b-tabs content-class="mt-3">
        <b-tab title="TAREAS A LIQUIDAR" active>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="searchTasks"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headerTasks"
                :items="tasks"
                :search="searchTasks"
                class="table-striped elevation-1"
                id="jobsTable"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Listado</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <ExportarTabla
                      filename="Tareas por pagar"
                      :data="tasks"
                      :header="headerTasks"
                    />
                  </v-toolbar>
                </template>
                <template v-slot:[`header.selected`]>
                  <v-switch
                    v-model="selectAllTasks"
                    @change="changeSelectAllTasks()"
                    :value="true"
                  ></v-switch>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <TaskDetail :taskID="item.idTarea"></TaskDetail>
                </template>
                <template v-slot:[`item.selected`]="{ item }">
                  <v-switch
                    v-model="item.selected"
                    v-on:change="changeSelectedState"
                  ></v-switch>
                </template>
                <template v-slot:[`item.cantidadGestiones`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "cantidadGestiones")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.costoTarea`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "costoTarea")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.reembolsoCosto`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "reembolsoCosto")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.totalCosto`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "totalCosto")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.precioTarea`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "precioTarea")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.totalVenta`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "totalVenta")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.margen`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "margen")
                      .render(item)
                  }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </b-tab>
        <b-tab title="TAREAS CON PROBLEMAS">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="searchTasksWithProblems"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headerTasks"
                :items="tasksWithProblems"
                :search="searchTasksWithProblems"
                class="table-striped elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Listado</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <ExportarTabla
                      filename="Tareas por pagar"
                      :data="tasksWithProblems"
                      :header="headerTasks"
                    />
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <TaskDetail :taskID="item.idTarea"></TaskDetail>
                </template>
                <template v-slot:[`item.selected`]></template>
                <template v-slot:[`item.cantidadGestiones`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "cantidadGestiones")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.costoTarea`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "costoTarea")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.reembolsoCosto`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "reembolsoCosto")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.totalCosto`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "totalCosto")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.precioTarea`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "precioTarea")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.totalVenta`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "totalVenta")
                      .render(item)
                  }}
                </template>
                <template v-slot:[`item.margen`]="{ item }">
                  {{
                    headerTasks
                      .find((aHeader) => aHeader.value === "margen")
                      .render(item)
                  }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </b-tab>
      </b-tabs>
    </v-app>
  </div>
</template>
<script>
import TaskDetail from "../task/TaskDetail.vue";
import ExportarTabla from "../globals/ExportarTabla.vue";
export default {
  name: "LiquidationsTasks",
  props: {
    tasks: Array,
    tasksWithProblems: Array,
  },
  components: {
    TaskDetail,
    ExportarTabla,
  },
  data() {
    const oVue = this;
    return {
      internalTasks: [...oVue.tasks],
      headerTasks: [
        {
          text: "",
          value: "selected",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
        },
        {
          text: "ID",
          value: "idTarea",
        },
        {
          text: "Fecha Gestión",
          value: "fechaGestion",
        },
        {
          text: "Jobber",
          value: "nombreJobber",
        },
        {
          text: "Comuna",
          value: "comunaTarea",
        },
        {
          text: "Mandante",
          value: "mandante",
        },
        {
          text: "ID Campaña",
          value: "idCampana",
        },
        {
          text: "Nombre Campaña",
          value: "nombreCampana",
        },
        {
          text: "Tipo Campaña",
          value: "tipoCampana",
        },
        {
          text: "Gestiones",
          value: "cantidadGestiones",
          render: (item) =>
            item.cantidadGestiones
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Costo",
          value: "costoTarea",
          render: (item) =>
            item.costoTarea
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Reembolso",
          value: "reembolsoCosto",
          render: (item) =>
            item.reembolsoCosto
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Total costo",
          value: "totalCosto",
          render: (item) =>
            (item.costoTarea * item.cantidadGestiones + item.reembolsoCosto)
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Provincia",
          value: "provinciaTarea",
        },
        {
          text: "Region",
          value: "regionTarea",
        },
        {
          text: "Venta",
          value: "precioTarea",
          render: (item) =>
            item.precioTarea
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Total venta",
          value: "totalVenta",
          render: (item) =>
            (item.precioTarea * item.cantidadGestiones)
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Margen",
          value: "margen",
          render: (item) =>
            item.margen
              .toString()
              .split(/(?=(?:\d{3})+(?:\.|$))/g)
              .join("."),
        },
        {
          text: "Tipo Comuna",
          value: "viaComuna",
        },
      ],
      searchTasks: "",
      searchTasksWithProblems: "",
      selectAllTasks: false,
    };
  },
  created() {},
  methods: {
    changeSelectAllTasks() {
      const oVue = this;
      const bSelectAll = oVue.selectAllTasks === null ? false : true;
      const aTasks = oVue.tasks.map((aTask) => {
        aTask.selected = bSelectAll;
        return {
          ...aTask,
        };
      });
      oVue.internalTasks = aTasks;
      oVue.returnSelectedTasks();
    },
    changeSelectedState(aTask) {
      const oVue = this;
      const iTaskID = aTask.idTarea;
      const aTasks = oVue.tasks.map((aInsideTask) => {
        if (aInsideTask.idTarea === iTaskID) {
          aInsideTask.selected = !aInsideTask.selected;
        }
        return {
          ...aInsideTask,
        };
      });
      oVue.internalTasks = aTasks;
      oVue.returnSelectedTasks();
    },
    returnSelectedTasks() {
      const oVue = this;

      const aSelectedTasks = oVue.internalTasks.filter((aTask) => {
        return aTask.selected;
      });
      oVue.$emit("getSelectedTasks", aSelectedTasks);
    },
  },
  watch: {
    internalTasks() {
      const oVue = this;
      oVue.returnSelectedTasks();
    },
  },
};
</script>
