<template>
  <div data-app>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title class="headline"> TOTAL </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <PayableAmountsRows
                  title="Cantidad de Tareas"
                  :value="allTasks.length"
                />
              </v-col>
              <v-col cols="12">
                <PayableAmountsRows
                  title="Cantidad de Jobbers"
                  :value="all.jobbersCount"
                />
              </v-col>
              <v-col cols="12">
                <PayableAmountsRows
                  title="Cantidad a Pagar"
                  :value="all.totalAmount"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="headline"> SELECCIONADO </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <PayableAmountsRows
                  title="Cantidad de Tareas"
                  :value="selectedTasks.length"
                />
              </v-col>
              <v-col cols="12">
                <PayableAmountsRows
                  title="Cantidad de Jobbers"
                  :value="selecteds.jobbersCount"
                />
              </v-col>
              <v-col cols="12">
                <PayableAmountsRows
                  title="Cantidad a Pagar"
                  :value="selecteds.totalAmount"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PayableAmountsRows from "./PayableAmountsRow.vue";
export default {
  name: "LiquidationsPayableAmounts",
  props: {
    allTasks: Array,
    selectedTasks: Array,
  },
  components: {
    PayableAmountsRows,
  },
  data() {
    return {
      all: {
        jobbersCount: 0,
        totalAmount: 0,
      },
      selecteds: {
        jobbersCount: 0,
        totalAmount: 0,
      },
    };
  },
  created() {},
  methods: {},
  watch: {
    allTasks() {
      const oVue = this;

      oVue.all.jobbersCount = Object.values(
        oVue.allTasks.reduce((acc, el) => {
          (acc[parseInt(el["jobber"])] =
            acc[parseInt(el["jobber"])] || []).push(el);
          return acc;
        }, {})
      ).length;

      oVue.all.totalAmount =
        oVue.allTasks.length > 0
          ? oVue.allTasks.reduce(
              (acc, el) =>
                acc +
                (el.costoTarea * el.cantidadGestiones + el.reembolsoCosto),
              0
            )
          : 0;
    },
    selectedTasks() {
      const oVue = this;

      oVue.selecteds.jobbersCount = Object.values(
        oVue.selectedTasks.reduce((acc, el) => {
          (acc[parseInt(el["jobber"])] =
            acc[parseInt(el["jobber"])] || []).push(el);
          return acc;
        }, {})
      ).length;

      oVue.selecteds.totalAmount =
        oVue.selectedTasks.length > 0
          ? oVue.selectedTasks.reduce(
              (acc, el) =>
                acc +
                (el.costoTarea * el.cantidadGestiones + el.reembolsoCosto),
              0
            )
          : 0;
    },
  },
};
</script>
